const rows = document.querySelectorAll('.toggle-checkbox')

rows.forEach(row => {
  row.addEventListener('click', () => {
    toggleCheckbox(row)
  })
})

const toggleCheckbox = (row) => {
  console.log(row)
  const checkbox = row.getElementsByTagName('input')[0]
  checkbox.checked = !checkbox.checked
}
